import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import SplitContentImage from "../components/SplitContentImage";
import React from "react";
import { SectionSpacer } from "../feature/blog/layout/SectionSpacer";
import { graphql } from "gatsby";
import { FeatureList } from "../components/FeatureList";
import { GatsbyImage } from "gatsby-plugin-image";

const About = ({ data }) => {
  console.log(data);
  return (
    <>
      <SEO title="About HueBox" />
      <Layout>
        <BreadcrumbBanner
          title="About HueBox"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="About HueBox"
        />
        <div className="main-content">
          <SectionSpacer>
            <SplitContentImage
              title="Frame"
              image={
                <GatsbyImage
                  image={data.systemImage.childImageSharp.gatsbyImageData}
                />
              }
            >
              <p>
                Designed around its versatile 120mm modular aluminium profile
                holding stretched silicon edge fabric graphics, HueBox light
                boxes can stand on their own feet, be wall mounted or even
                suspended in a range of configurations including advanced
                ceiling lighting.
              </p>
            </SplitContentImage>

            <SplitContentImage
              title="LEDs"
              image={
                <GatsbyImage
                  image={data.ledsImage.childImageSharp.gatsbyImageData}
                />
              }
              reverse
            >
              <FeatureList
                items={[
                  {
                    title: "Dynamic",
                    content: `Our boxes use individually addressable LED's meaning we can control the exact brightness of each light individually`,
                  },
                  {
                    title: "Detailed",
                    content: `Over 600 LEDs per square meter allow for detailed scenes to be created`,
                  },
                  {
                    title: "Dynamic Colours",
                    content: `Available options include full RGB lighting capable of showing over 16 million different colours.`,
                  },
                ]}
              />
            </SplitContentImage>

            <SplitContentImage
              title="Fabric Graphics"
              image={
                <GatsbyImage
                  image={data.printerImage.childImageSharp.gatsbyImageData}
                />
              }
            >
              <FeatureList
                items={[
                  {
                    title: "Flexible",
                    content: `Available in 0.5 meter increments up to 5m x 10m`,
                  },
                  {
                    title: "Durable",
                    content: `Fabric is much more forgiving than other printed graphics and stays looking new for longer.`,
                  },
                  {
                    title: "Vibrant",
                    content: `Printed using the widest colour gamut HP Latex printers`,
                  },
                  {
                    title: "Silicon Edge",
                    content: `A silicone strip along each edge sewn directly to the graphic, is then inserted into a groove on the frame. No tools required.`,
                  },
                  {
                    title: "Eco friendly",
                    content: `All graphics are printed using eco-friendly Greenguard HP Latex ink`,
                  },
                ]}
              />
            </SplitContentImage>

            <SplitContentImage
              title="Animations"
              image={
                <GatsbyImage
                  image={data.animationsImage.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                />
              }
              reverse
            >
              <FeatureList
                items={[
                  {
                    title: "Highlight parts of the image",
                    content: `Animate text or important features.`,
                  },
                  {
                    title: "Play videos behind your product",
                    content: `Add atmosphere to your brand, with animations that complement your brand without distracting from it.`,
                  },
                  {
                    title: "Bespoke animations",
                    content: `Our professional team of animators can work with you to make your vision a reality.`,
                  },
                ]}
              />
            </SplitContentImage>
            <SplitContentImage
              title="Mounting options"
              image={
                <GatsbyImage
                  image={data.mountingImage.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                />
              }
            >
              <FeatureList
                items={[
                  {
                    title: "Freestanding",
                    content: `Removable feet are provided for floor mounted boxes, providing a sturdy base for the lightbox to be freestanding.`,
                  },
                  {
                    title: "Wall mounted",
                    content: `With provided brackets our boxes can be mounted on, or in walls or furniture.`,
                  },
                  {
                    title: "Suspended",
                    content: `Suspension cables allow HueBoxes to be hung from the ceiling. The reinforced frame is strong enough to not bow.`,
                  },
                ]}
              />
            </SplitContentImage>
          </SectionSpacer>
        </div>
      </Layout>
    </>
  );
};
export default About;
export const query = graphql`
  {
    immersiveImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Colour_Test_Gradient_Material_LONG_Material_OFF.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    systemImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Material_OFF_Cross_Section_Close.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    ledsImage: file(
      relativePath: { eq: "Abstract_RGB_Board_Curtain_White.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    printerImage: file(relativePath: { eq: "Printer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    mountingImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Material_OFF_Curtain_x3_Columns.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    animationsImage: file(
      relativePath: { eq: "designer-drawing-on-pc-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;
